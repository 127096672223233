import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { FHButton } from "../FHButton";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
}));

type BasketContinueButtonProps = {
  label?: string;
  loading?: boolean;
  onClick?: () => void;
  disableContinueCTA?: boolean;
};

export const ContinueButton: React.FC<BasketContinueButtonProps> = ({
  label = "Continue",
  loading = false,
  onClick,
  disableContinueCTA = false,
}: PropsWithChildren<BasketContinueButtonProps>) => {
  const classes = useStyles();

  return (
    <>
      {!!onClick && !loading && (
        <div className={classNames(classes.root)}>
          <FHButton
            fullWidth={true}
            onClick={onClick}
            disabled={disableContinueCTA}
          >
            {label}
          </FHButton>
        </div>
      )}
    </>
  );
};
