import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FHDrawer from "../FHDrawer";
import FHButton from "../FHButton";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    position: "relative",
    padding: `${theme.spacing(1.5)} !important`,
  },
  totalItems: {
    fontSize: 18,
    position: "absolute",
    right: theme.spacing(-1),
    top: theme.spacing(-1.5),
    backgroundColor: theme.palette.primary.dark,
    width: 22,
    height: 22,
    borderRadius: "50%",
    color: theme.palette.background.default,
    lineHeight: `${theme.spacing(1.8)}`,
  },
  buttonLabel: {
    marginRight: theme.spacing(0.5),
  },
  label: {
    [theme.breakpoints.down(360)]: {
      display: "none",
    },
  },
}));

type MobileBasketWrapperProps = {
  basketSubtitle?: string;
  basketTitle?: string;
  closeIcon?: JSX.Element;
  isMobile?: boolean;
  mobileDrawerButtonIcon?: JSX.Element;
  totalItems?: number;
  mobileBasketButtonLabel?: string;
};

export const MobileBasketWrapper: React.FC<
  PropsWithChildren<MobileBasketWrapperProps>
> = ({
  children,
  isMobile,
  mobileDrawerButtonIcon = <ShoppingCartIcon />,
  totalItems = 0,
  mobileBasketButtonLabel,
}: PropsWithChildren<MobileBasketWrapperProps>) => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const showCartItems = totalItems > 0 ? true : false;

  return isMobile ? (
    <>
      {/* it's essential that button is in here separately from everything else to avoid uneccessary re-rendering */}
      <FHButton
        classes={{
          root: classes.buttonRoot,
        }}
        fhSize="md"
        onClick={() => setShowDrawer(true)}
      >
        {showCartItems && (
          <Typography variant="h1" className={classes.totalItems}>
            {totalItems}
          </Typography>
        )}
        {mobileBasketButtonLabel && (
          <span className={classes.buttonLabel}>{mobileBasketButtonLabel}</span>
        )}
        {mobileDrawerButtonIcon && mobileDrawerButtonIcon}
      </FHButton>
      <FHDrawer
        isShowCloseButton
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        {children}
      </FHDrawer>
    </>
  ) : (
    <>{children}</>
  );
};
